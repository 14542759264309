import { ReactElement, ReactNode, useState } from "react";

import { pageAnimationContext } from "@context/Contexts";
import { PageAnimationDirectionType } from "@typing/State";

const PageAnimationStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [animationDirectionState, setAnimationDirectionState] = useState<PageAnimationDirectionType>(undefined);

  return (
    <pageAnimationContext.Provider
      value={{
        animationDirectionState,
        setAnimationDirectionState
      }}
    >
      <>{children}</>
    </pageAnimationContext.Provider>
  );
};

export default PageAnimationStore;
