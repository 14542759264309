import * as Sentry from "@sentry/react";
import { FormatError } from "intl-messageformat";
import { ComponentProps, useCallback, useMemo } from "react";
import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError,
  IntlProvider as WrappedIntlProvider
} from "react-intl";

import useMe from "@hooks/useMe";
import { isValidTimezone } from "@utils/timeUtils";

type Props = ComponentProps<typeof WrappedIntlProvider>;

const IntlProvider = ({ children, ...providerProps }: Props) => {
  const me = useMe();

  const timezone = useMemo(() => {
    if (me && isValidTimezone(me.timezone)) {
      return me.timezone!;
    }
    return "America/New_York";
  }, [me]);

  const handleError = useCallback(
    (
      error:
        | MissingTranslationError
        | MessageFormatError
        | MissingDataError
        | InvalidConfigError
        | UnsupportedFormatterError
        | FormatError
    ) => {
      Sentry.captureException(error);
      if (import.meta.env.VITE_LOG_TRANSLATION_ERRORS === "yes") {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    []
  );

  return (
    <WrappedIntlProvider {...providerProps} onError={handleError} timeZone={timezone}>
      {children}
    </WrappedIntlProvider>
  );
};

export default IntlProvider;
