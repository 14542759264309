import { useContext } from "react";

import { sessionContext } from "@context/Contexts";
import { GetMeQuery } from "@typing/Generated";

type MyAdmin = Extract<GetMeQuery["me"], { __typename: "Admin" }>;

const useMyAdmin: () => MyAdmin | undefined = () => {
  const { sessionState } = useContext(sessionContext);

  const me = sessionState.me;
  return me?.__typename === "Admin" ? me : undefined;
};

export default useMyAdmin;
