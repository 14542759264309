import { IntlShape } from "react-intl";
import { AutoClear, CookieConsentConfig, CookieTable, CookieValue } from "vanilla-cookieconsent";

import { CookieConsentContextType } from "@typing/State";

export type Cookie = {
  cookie: string;
  domain: string;
  isRegex?: boolean;
  vendor: string;
};

enum CookieCategory {
  ANALYTICS = "ANALYTICS",
  FUNCTIONAL = "FUNCTIONAL",
  NECESSARY = "NECESSARY",
  PAYMENT = "PAYMENT"
}

const toCookieBannerFormat = (cookies: Cookie[], intl: IntlShape): CookieTable => {
  const columns = cookies.map(cookie => ({
    domain: cookie.domain,
    name: cookie.cookie,
    origin: cookie.vendor
  }));

  return {
    body: columns,
    headers: {
      domain: intl.formatMessage({ id: "pages.cookieBanner.domain" }),
      name: intl.formatMessage({ id: "pages.cookieBanner.name" }),
      origin: intl.formatMessage({ id: "pages.cookieBanner.origin" })
    }
  };
};

const toAutoClearFormat = (cookies: Cookie[]): AutoClear => {
  const values = cookies.map(cookie => {
    if (cookie.isRegex) {
      return { name: new RegExp(cookie.cookie) };
    }
    return { name: cookie.cookie };
  });
  return { cookies: values };
};

export const necessaryCookies: Cookie[] = [
  {
    cookie: "_grecaptcha",
    domain: "google.com",
    vendor: "Google reCAPTCHA"
  },
  {
    cookie: "cc_cookie",
    domain: "withgrayce.com",
    vendor: "Grayce"
  }
];

export const functionalCookies: Cookie[] = [
  {
    cookie: "_delighted_web",
    domain: "withgrayce.com",
    vendor: "Delighted"
  },
  {
    cookie: "do",
    domain: "delighted.com",
    vendor: "Delighted"
  },
  {
    cookie: "intercom-*",
    domain: "delighted.com",
    isRegex: true,
    vendor: "Delighted"
  },
  {
    cookie: "lid",
    domain: "delighted.com",
    vendor: "Delighted"
  },
  {
    cookie: "plhi",
    domain: "delighted.com",
    vendor: "Delighted"
  },
  {
    cookie: "ms",
    domain: "delighted.com",
    vendor: "Delighted"
  },
  {
    cookie: "avc",
    domain: "delighted.com",
    vendor: "Delighted"
  },
  {
    cookie: "__kla_id",
    domain: "klaviyo.com",
    vendor: "Klaviyo"
  },
  {
    cookie: "kl_sessionid",
    domain: "klaviyo.com",
    vendor: "Klaviyo"
  },
  {
    cookie: "kl_csrftoken",
    domain: "klaviyo.com",
    vendor: "Klaviyo"
  }
];

export const analyticsCookies: Cookie[] = [
  {
    cookie: "_dd_s",
    domain: "withgrayce.com",
    vendor: "Datadog"
  },
  {
    cookie: "_ga*",
    domain: "withgrayce.com",
    isRegex: true,
    vendor: "Google"
  },
  {
    cookie: "_gid",
    domain: "google.com",
    vendor: "Google"
  },
  {
    cookie: "__kla_id",
    domain: "withgrayce.com",
    vendor: "Klaviyo"
  },
  {
    cookie: "__kla_off",
    domain: "withgrayce.com",
    vendor: "Klaviyo"
  },
  {
    cookie: "ab.storage.userId.*",
    domain: "withgrayce.com",
    isRegex: true,
    vendor: "Braze"
  },
  {
    cookie: "ab.storage.sessionId.*",
    domain: "withgrayce.com",
    isRegex: true,
    vendor: "Braze"
  },
  {
    cookie: "ab.storage.deviceId.*",
    domain: "withgrayce.com",
    isRegex: true,
    vendor: "Braze"
  },
  {
    cookie: "ab.optOut",
    domain: "withgrayce.com",
    vendor: "Braze"
  },
  {
    cookie: "ab._gd",
    domain: "withgrayce.com",
    vendor: "Braze"
  }
];

export const paymentCookies: Cookie[] = [
  {
    cookie: "stripe.csrf",
    domain: "stripe.com",
    vendor: "Stripe"
  },
  {
    cookie: "__stripe_orig_props",
    domain: "stripe.com",
    vendor: "Stripe"
  },
  {
    cookie: "__stripe_sid",
    domain: "withgrayce.com",
    vendor: "Stripe"
  },
  {
    cookie: "__stripe_mid",
    domain: "withgrayce.com",
    vendor: "Stripe"
  },
  {
    cookie: "machine_identifier",
    domain: "stripe.com",
    vendor: "Stripe"
  },
  {
    cookie: "private_machine_identifier",
    domain: "stripe.com",
    vendor: "Stripe"
  },
  {
    cookie: "cookie-perms",
    domain: "stripe.com",
    vendor: "Stripe"
  }
];

export const createCookieConsentConfig = (
  onAcceptanceChange: (status: CookieConsentContextType) => void,
  intl: IntlShape
): CookieConsentConfig => ({
  autoClearCookies: true,
  categories: {
    [CookieCategory.NECESSARY]: {
      autoClear: toAutoClearFormat(necessaryCookies),
      enabled: true,
      readOnly: true
    },
    [CookieCategory.ANALYTICS]: {
      autoClear: toAutoClearFormat(analyticsCookies),
      enabled: true,
      readOnly: false
    },
    [CookieCategory.FUNCTIONAL]: {
      autoClear: toAutoClearFormat(functionalCookies),
      enabled: true,
      readOnly: false
    },
    [CookieCategory.PAYMENT]: {
      autoClear: toAutoClearFormat(paymentCookies),
      enabled: true,
      readOnly: false
    }
  },
  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          acceptAllBtn: intl.formatMessage({ id: "pages.cookieBanner.acceptAll" }),
          acceptNecessaryBtn: intl.formatMessage({ id: "pages.cookieBanner.acceptNecessary" }),
          description: intl.formatMessage({ id: "pages.cookieBanner.description" }),
          title: intl.formatMessage({ id: "pages.cookieBanner.title" })
        },
        preferencesModal: {
          acceptAllBtn: intl.formatMessage({ id: "pages.cookieBanner.acceptAll" }),
          closeIconLabel: intl.formatMessage({ id: "pages.cookieBanner.preferences.close" }),
          savePreferencesBtn: intl.formatMessage({ id: "pages.cookieBanner.preferences.save" }),
          sections: [
            {
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.intro.description" }),
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.intro.title" })
            },
            {
              cookieTable: toCookieBannerFormat(necessaryCookies, intl),
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.necessary.description" }),
              linkedCategory: CookieCategory.NECESSARY,
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.necessary.title" })
            },
            {
              cookieTable: toCookieBannerFormat(functionalCookies, intl),
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.functionality.description" }),
              linkedCategory: CookieCategory.FUNCTIONAL,
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.functionality.title" })
            },

            {
              cookieTable: toCookieBannerFormat(analyticsCookies, intl),
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.analytics.description" }),
              linkedCategory: CookieCategory.ANALYTICS,
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.analytics.title" })
            },
            {
              cookieTable: toCookieBannerFormat(paymentCookies, intl),
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.payment.description" }),
              linkedCategory: CookieCategory.PAYMENT,
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.payment.title" })
            },
            {
              description: intl.formatMessage({ id: "pages.cookieBanner.preferences.moreInfo.description" }),
              title: intl.formatMessage({ id: "pages.cookieBanner.preferences.moreInfo.title" })
            }
          ],
          title: intl.formatMessage({ id: "pages.cookieBanner.preferences.title" })
        }
      }
    }
  },

  onChange: function ({
    cookie
  }: {
    changedCategories: string[];
    changedServices: Record<string, string[]>;
    cookie: CookieValue;
  }) {
    onAcceptanceChange({
      acceptsAnalyticsCookies: cookie.categories.includes(CookieCategory.ANALYTICS),
      acceptsFunctionalCookies: cookie.categories.includes(CookieCategory.FUNCTIONAL),
      acceptsNecessaryCookies: cookie.categories.includes(CookieCategory.NECESSARY),
      acceptsPaymentCookies: cookie.categories.includes(CookieCategory.PAYMENT)
    });
  },

  onConsent: function ({ cookie }: { cookie: CookieValue }) {
    onAcceptanceChange({
      acceptsAnalyticsCookies: cookie.categories.includes(CookieCategory.ANALYTICS),
      acceptsFunctionalCookies: cookie.categories.includes(CookieCategory.FUNCTIONAL),
      acceptsNecessaryCookies: cookie.categories.includes(CookieCategory.NECESSARY),
      acceptsPaymentCookies: cookie.categories.includes(CookieCategory.PAYMENT)
    });
  },

  onFirstConsent: function ({ cookie }: { cookie: CookieValue }) {
    onAcceptanceChange({
      acceptsAnalyticsCookies: cookie.categories.includes(CookieCategory.ANALYTICS),
      acceptsFunctionalCookies: cookie.categories.includes(CookieCategory.FUNCTIONAL),
      acceptsNecessaryCookies: cookie.categories.includes(CookieCategory.NECESSARY),
      acceptsPaymentCookies: cookie.categories.includes(CookieCategory.PAYMENT)
    });
  }
});
