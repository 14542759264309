import { App } from "@capacitor/app";
import * as LiveUpdates from "@capacitor/live-updates";
import { AppUpdate, AppUpdateAvailability } from "@capawesome/capacitor-app-update";
import { ReactNode, useEffect, useState } from "react";

import { appUpdatedContext } from "@context/Contexts";
import useInterval from "@hooks/useInterval";
import {
  blockLiveUpdatesStorageKey,
  liveUpdateAvailableStorageKey,
  storageTrueValue,
  twoMinutesInMs
} from "@typing/Constants";
import { isNativeApp } from "@utils/platformUtils";
import Storage from "@utils/storageUtils";

type Props = {
  children: ReactNode;
};

const AppUpdatedProvider = ({ children }: Props) => {
  const { addInterval } = useInterval();
  const [assetManifest, setAssetManifest] = useState<string | null>(null);
  const [assetManifestChanged, setAssetManifestChanged] = useState<boolean>(false);
  const [newAppVersionAvailable, setNewAppVersionAvailable] = useState<boolean>(false);

  App.addListener("resume", () => {
    Promise.all([Storage.get(liveUpdateAvailableStorageKey), Storage.get(blockLiveUpdatesStorageKey)]).then(
      ([reloadNeeded, reloadBlocked]) => {
        if (reloadNeeded === storageTrueValue && reloadBlocked !== storageTrueValue) {
          Storage.remove(liveUpdateAvailableStorageKey).then(() => {
            LiveUpdates.reload();
          });
        }
      }
    );
  });

  useEffect(() => {
    // In mobile apps there are two types of updates to look out for: new releases of the app and
    // live updates of the assets. Scan for those periodically. On the web, live updates do not work
    // so we scan for updates to the asset manifest.
    if (isNativeApp()) {
      const checkForNewAppVersion = async () => {
        // This plugin does not seem to work in the Android emulator, will need to try to test it
        // with a Google Play build. It does work in the iOS simulator, but you cannot open links to
        // the app store there, so it is not fully testable.
        try {
          const result = await AppUpdate.getAppUpdateInfo();
          if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
            setNewAppVersionAvailable(true);
          }
        } catch {
          // nada
        }
      };

      const checkForLiveUpdate = async () => {
        // Our code only applies the updates when you explicitly ask it to. But if you ask the capacitor
        // plugin to check for updates and it finds one then it will go ahead and download it, and it will
        // apply it the next time you start up the app, even though you have explicitly told it not to.
        // So we do need to fully disable this crap in dev mode so that we don't even check.
        // On the plus side, this means that if we have previously found an update then it will automatically
        // be installed for the user when they next start up the app.
        // This has been confirmed to work in the iOS and android simulators.
        if (import.meta.env.VITE_PERFORM_LIVE_UPDATES === "yes") {
          try {
            Storage.get(liveUpdateAvailableStorageKey).then(storageCache => {
              if (storageCache !== storageTrueValue) {
                LiveUpdates.sync().then(result => {
                  if (result.activeApplicationPathChanged) {
                    Storage.set(liveUpdateAvailableStorageKey, storageTrueValue);
                  }
                });
              }
            });
          } catch {
            // nada
          }
        }
      };

      checkForNewAppVersion();
      checkForLiveUpdate();

      addInterval(() => {
        checkForNewAppVersion();
        checkForLiveUpdate();
      }, twoMinutesInMs);
    } else {
      if (import.meta.env.VITE_PERFORM_DEPLOY_CHECKS === "yes") {
        if (!assetManifestChanged) {
          addInterval(() => {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", "asset-manifest.json", true);
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.setRequestHeader("Expires", "Tue, 01 Jan 1980 1:00:00 GMT");
            xhr.setRequestHeader("Pragma", "no-cache");
            xhr.onload = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  // When run locally, this will return the index.html page because the asset manifest does not
                  // exist, and that page will include a timestamp that makes it appear that the asset manifest keeps
                  // changing if you ever modify the code.
                  const contentType = xhr.getResponseHeader("content-type");
                  if (contentType?.toLowerCase() === "application/json") {
                    const allText = xhr.responseText;
                    const changed = assetManifest !== null && allText !== assetManifest;

                    setAssetManifest(allText);
                    setAssetManifestChanged(changed);
                  }
                }
              }
            };
            xhr.send(null);
          }, twoMinutesInMs);
        }
      }
    }
  }, [addInterval, assetManifest, assetManifestChanged]);

  return (
    <appUpdatedContext.Provider value={{ assetManifestChanged, newAppVersionAvailable }}>
      {children}
    </appUpdatedContext.Provider>
  );
};

export default AppUpdatedProvider;
