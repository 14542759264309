import { useContext, useEffect } from "react";

import { setCommunityMe } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import useLazyQueryWithPromise from "@hooks/useLazyQueryWithPromise";
import { GetCommunityMeDocument, GetCommunityMeQuery, GetCommunityMeQueryVariables } from "@typing/Generated";

const GetCommunityMe = () => {
  const session = useContext(sessionContext);

  const getCommunityMe = useLazyQueryWithPromise<GetCommunityMeQuery, GetCommunityMeQueryVariables>(
    GetCommunityMeDocument
  );

  useEffect(() => {
    const authToken = session.sessionState.authToken;
    if (authToken) {
      getCommunityMe().then(result => {
        const communityMe = result.data.communityCurrentUser;
        session.dispatch(setCommunityMe(communityMe));
      });
    }
  }, [getCommunityMe, session]);

  return null;
};

export default GetCommunityMe;
