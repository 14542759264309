import { useContext } from "react";

import { sessionContext } from "@context/Contexts";
import { GetMeQuery } from "@typing/Generated";

type MyExpert = Extract<GetMeQuery["me"], { __typename: "Expert" }>;

const useMyExpert: () => MyExpert | undefined = () => {
  const { sessionState } = useContext(sessionContext);

  const me = sessionState.me;
  return me?.__typename === "Expert" ? me : undefined;
};

export default useMyExpert;
