import { ReactElement, ReactNode, useState } from "react";

import { bannerContext } from "@context/Contexts";
import { BannerKindEnum } from "@typing/Generated";
import { Banner } from "@typing/State";

const BannerStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [currentBanner, setCurrentBanner] = useState<Banner | undefined>();
  const [sessionDismissedBannerKinds, setSessionDismissedBannerKinds] = useState<string[]>([]);

  const changeCurrentBanner = (banner: Banner) => {
    setCurrentBanner(banner);
  };

  const dismissBannerKind = (kind: BannerKindEnum) => {
    if (!sessionDismissedBannerKinds.includes(currentBanner?.kind ?? "")) {
      setSessionDismissedBannerKinds([...sessionDismissedBannerKinds, kind]);
    }
    if (currentBanner?.kind === kind) {
      setCurrentBanner(undefined);
    }
  };

  const deleteCurrentBanner = () => {
    if (currentBanner?.kind) {
      dismissBannerKind(currentBanner.kind);
    }
    setCurrentBanner(undefined);
  };

  return (
    <bannerContext.Provider
      value={{
        changeCurrentBanner,
        currentBanner,
        deleteCurrentBanner,
        dismissBannerKind,
        sessionDismissedBannerKinds
      }}
    >
      {children}
    </bannerContext.Provider>
  );
};

export default BannerStore;
