// eslint-disable-next-line import/no-unused-modules
import { datadogLogs } from "@datadog/browser-logs";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Breadcrumb, init as sentryInit } from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import App from "@components/v1/App";
import SessionStore from "@context/SessionStore";

if (import.meta.env.VITE_DATADOG_LOGS_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_LOGS_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    service: `browser-${import.meta.env.VITE_ENVIRONMENT_NAME}`,
    sessionSampleRate: 100,
    trackingConsent: "not-granted"
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);

if (import.meta.env.VITE_SENTRY_DSN) {
  sentryInit({
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "fetch") {
        const url = (breadcrumb?.data?.url as string | null) ?? "";
        if (/graphql/.exec(url)) {
          const input = hint?.input as { body: string }[];
          const body = input[1]?.body;
          if (body) {
            const bodyData = JSON.parse(body) as Record<string, unknown>;

            const newBreadcrumb: Breadcrumb = {
              category: "graphql",
              data: bodyData.variables as Record<string, any>,
              level: "info",
              message: `${breadcrumb.data?.status_code as number} ${bodyData.operationName as string}`
            };
            return newBreadcrumb;
          }
        }
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      // https://github.com/getsentry/sentry-javascript/issues/2514
      if (hint?.originalException === "Timeout") {
        return null;
      }
      return event;
    },
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT_NAME,
    ignoreErrors: [
      "2fa_required",
      "Cancel button was pressed",
      "Cannot read property 'isProxied' of undefined",
      "Cannot read properties of undefined (reading 'isProxied')",
      "ChunkLoadError",
      "Failed to fetch",
      "Failed to fetch dynamically imported module",
      "Loading chunk",
      "InitializationError",
      "invalid_jwt",
      "Load failed",
      "ResizeObserver loop limit exceeded",
      "Unable to preload CSS",
      "unauthorized",
      "AbortError",
      "Cancel button was pressed",
      "XMLHttpRequestProgressEvent", // Klaviyo
      "Blocked 'frame-ancestors'", // Not sure why this counts as an error since it's what we want
      "window.interactionRecorder.getMissingFnName",
      "reading 'nodeSize'", // a TipTap thing that seems non-problematic
      "The provided value is non-finite", // Some ionic menu animation issue that does not actually cause a problem
      "Failed to construct 'CustomElement': The result must not have attributes", // Some weird thing with stencil that we can't reproduce
      "Object captured as promise rejection with keys: appId, failStep, message", // Android attempt to check for updates while check is in progress
      "A profile already exists with one of these identifiers", // Klaviyo thing that I'm not going to bother fixing
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:.*, MethodName:.*, ParamCount:.*/,
      /".*" plugin is not implemented on .*/,
      /.*GA4React.*/
    ],

    integrations: [],

    maxValueLength: 20000,
    release: import.meta.env.VITE_RELEASE_SHA ?? undefined,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0
  });
}

root.render(
  <HelmetProvider>
    <Helmet>
      <meta content="noindex, nofollow" name="robots" />
      <meta
        content={`
          base-uri 'self';
          child-src 'self' blob:;
          connect-src
            'self'
            data:
            https://*.sentry.io
            https://*.klaviyo.com
            https://web.delighted.com
            https://www.google-analytics.com
            wss://ws.pusherapp.com
            https://maps.googleapis.com
            https://maps.google.com
            https://us-central1-adaptive-growth.cloudfunctions.net
            https://bat.bing.com
            ${import.meta.env.VITE_ACTIONCABLE_URL}
            ${import.meta.env.VITE_API_HOST}
            ${import.meta.env.VITE_CREATE_BLOB_URL}
            ${import.meta.env.VITE_MONOLITH_HOST}
            http://localhost:3000
            http://google.com
            https://api.ionicjs.com
            https://pro-deploy.ionicjs.com
            https://js.stripe.com
            https://sdk.iad-02.braze.com
            https://browser-intake-datadoghq.com
            ${import.meta.env.VITE_DEMO_BACKUP_MANAGER_URL}
            ${import.meta.env.VITE_ATTACHMENT_HOST};
          default-src ;
          font-src
            'self'
            https://fonts.googleapis.com
            https://fonts.gstatic.com
            data:;
          frame-src
            https://www.google.com/
            https://js.stripe.com
            https://player.vimeo.com
            https://maps.google.com
            https://maps.googleapis.com
            https://app.hellosign.com/
            https://community.withgrayce.com
            blob:;
          img-src
            'self'
            blob:
            data:
            http://*.klaviyo.com
            https://*.klaviyo.com
            https://www.googletagmanager.com
            https://*.cloudfront.net
            https://*.vimeocdn.com
            https://res.cloudinary.com
            https://twemoji.maxcdn.com
            ${import.meta.env.VITE_ATTACHMENT_HOST}
            ${import.meta.env.VITE_MEDIA_HOST}
            ${import.meta.env.VITE_COMMUNITY_MEDIA_HOST};
          manifest-src
            'self';
          media-src
            'self'
            ${import.meta.env.VITE_ATTACHMENT_HOST}
            ${import.meta.env.VITE_MEDIA_HOST}
            ${import.meta.env.VITE_COMMUNITY_MEDIA_HOST};
          object-src 'none';
          script-src
            'self'
            data:
            'unsafe-inline'
            https://www.google.com
            https://www.gstatic.com/
            https://js.stripe.com
            https://d2yyd1h5u9mauk.cloudfront.net
            https://maps.googleapis.com
            https://maps.gstatic.com
            https://www.googletagmanager.com
            https://maps.googleapis.com
            http://static.klaviyo.com
            https://static.klaviyo.com
            https://static-tracking.klaviyo.com
            https://appleid.cdn-apple.com/
            https://*.ingest.sentry.io
            ${import.meta.env.VITE_DEPLOY_HOST};
          style-src
            'self'
            'unsafe-inline'
            https://static.klaviyo.com
            https://static-tracking.klaviyo.com
            https://fonts.googleapis.com;
          worker-src
            'self'
            blob:;
        `}
        httpEquiv="Content-Security-Policy"
      />
    </Helmet>
    <SessionStore>
      <App />
    </SessionStore>
  </HelmetProvider>
);

// eslint-disable-next-line unicorn/prefer-global-this
defineCustomElements(window);
