import { ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router";

import useGlobalOpener from "@hooks/useGlobalOpener";
import useMe from "@hooks/useMe";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import { OpenerTypeEnum } from "@typing/Enums";
import {
  AppEventActionKindEnum,
  AppEventCreateDocument,
  AppEventCreateMutation,
  AppEventCreateMutationVariables
} from "@typing/Generated";

type Props = {
  children: ReactNode;
};

const EventTracker = ({ children }: Props) => {
  const messagesOpener = useGlobalOpener(OpenerTypeEnum.MESSAGES);

  const messagesIsOpen = messagesOpener.isOpen;
  const location = useLocation();
  const me = useMe();

  // This is a little annoying, but I can't get the journey id from the params unless this is mounted within the
  // routers that declare that param, and that feels more error-prone than hacking it out like this.
  const journeyId = useMemo(() => {
    const match = /journeys\/([a-z0-9-]*)/.exec(location.pathname);
    if (match) {
      return match[1];
    }
    return null;
  }, [location]);

  const [createAppEvent] = useMutationWithValidation<AppEventCreateMutation, AppEventCreateMutationVariables>(
    AppEventCreateDocument,
    "appEvent"
  );

  useEffect(() => {
    if (journeyId && me && messagesIsOpen) {
      createAppEvent({
        actionKind: AppEventActionKindEnum.MESSAGES_OPENED,
        journeyId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyId, me, messagesIsOpen]);

  return <>{children}</>;
};

export default EventTracker;
