import { useEffect, useRef } from "react";

const useInterval = () => {
  const intervals = useRef<NodeJS.Timeout[]>([]);

  const clearIntervals = () => {
    intervals.current.forEach(interval => {
      clearInterval(interval);
    });
    intervals.current = [];
  };

  useEffect(
    () => () => {
      clearIntervals();
    },
    []
  );

  const removeInterval = (interval: NodeJS.Timeout) => {
    intervals.current = intervals.current.filter(i => i !== interval);
  };

  const addInterval = (callback: () => void, duration: number) => {
    // eslint-disable-next-line no-restricted-globals
    const interval = setInterval(() => {
      callback();
      removeInterval(interval);
    }, duration);
    intervals.current.push(interval);
    return interval;
  };

  return { addInterval, clearIntervals, intervals };
};

export default useInterval;
