import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, useHistory, useLocation } from "react-router";

import styles from "@components/v1/auth/LoginSuccessPage.module.css";
import BodyCopy from "@components/v1/typography/BodyCopy";
import Icon from "@components/v2/icons/Icon";

const FakeGoogleAuthPage = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const emailParam = params.get("email");
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT_NAME === "development") {
      fetch(`${import.meta.env.VITE_MONOLITH_HOST}/fake_google_auths?email=${emailParam}`, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "Content-Type": "application/json"
        },
        method: "POST"
      }).then(response => {
        if (response.ok) {
          response.json().then(json => {
            history.replace(`/auth/login-success?jwt=${json.jwt}`);
          });
        } else {
          setFailed(true);
        }
      });
    }
  }, [emailParam, history]);

  if (import.meta.env.VITE_ENVIRONMENT_NAME !== "development") {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.center}>
      {failed ? (
        <BodyCopy>
          <FormattedMessage id="pages.fakeGoogleAuth.failure" />
        </BodyCopy>
      ) : (
        <Icon icon="spinner" size="xlarge" />
      )}
    </div>
  );
};

export default FakeGoogleAuthPage;
