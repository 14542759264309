import { useEffect } from "react";
import { useLocation } from "react-router";

import storage from "@utils/storageUtils";

const UtmHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = searchParams.get("utm_campaign");
  const utmContent = searchParams.get("utm_content");
  const utmMedium = searchParams.get("utm_medium");
  const utmSource = searchParams.get("utm_source");
  const utmTerm = searchParams.get("utm_term");

  useEffect(() => {
    if (utmCampaign) {
      storage.set("utmCampaign", utmCampaign);
    }
    if (utmContent) {
      storage.set("utmContent", utmContent);
    }
    if (utmMedium) {
      storage.set("utmMedium", utmMedium);
    }
    if (utmSource) {
      storage.set("utmSource", utmSource);
    }
    if (utmTerm) {
      storage.set("utmTerm", utmTerm);
    }
  }, [utmCampaign, utmContent, utmMedium, utmSource, utmTerm]);

  return null;
};

export default UtmHandler;
