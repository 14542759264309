import { produce } from "immer";

import { SessionAction, SessionActionTypes } from "@actions/sessionActions";
import { SessionState } from "@typing/State";
import storage from "@utils/storageUtils";

const sessionReducer = (state: SessionState, action: SessionActionTypes): SessionState => {
  switch (action.type) {
    case SessionAction.CLEAR_AUTH_TOKENS:
      storage.remove("AUTH_TOKEN");
      storage.remove("IMPERSONATE_CLIENT_AUTH_TOKEN");
      storage.remove("IMPERSONATE_EXPERT_AUTH_TOKEN");
      return produce(state, draftState => {
        draftState.authToken = null;
        draftState.impersonateClientAuthToken = null;
        draftState.impersonatingClient = false;
        draftState.impersonateExpertAuthToken = null;
        draftState.impersonatingExpert = false;
        draftState.me = undefined;
        draftState.otpVerified = false;
      });
    case SessionAction.END_IMPERSONATION:
      storage.remove("IMPERSONATE_CLIENT_AUTH_TOKEN");
      storage.remove("IMPERSONATE_EXPERT_AUTH_TOKEN");
      return produce(state, draftState => {
        draftState.impersonateClientAuthToken = null;
        draftState.impersonatingClient = false;
        draftState.impersonateExpertAuthToken = null;
        draftState.impersonatingExpert = false;
        draftState.me = action.payload.user;
        draftState.otpVerified = true;
      });
    case SessionAction.SET_COMMUNITY_ME:
      return produce(state, draftState => {
        draftState.communityMe = action.payload.user;
      });
    case SessionAction.SET_ME:
      return produce(state, draftState => {
        draftState.me = action.payload.user;
      });
    case SessionAction.SET_OTP_VERIFIED:
      return produce(state, draftState => {
        draftState.otpVerified = action.payload.otpVerified;
      });
    case SessionAction.SET_AUTH_TOKEN:
      if (action.payload.authToken !== state.authToken) {
        storage.set("AUTH_TOKEN", action.payload.authToken);
        storage.remove("IMPERSONATE_CLIENT_AUTH_TOKEN");
        storage.remove("IMPERSONATE_EXPERT_AUTH_TOKEN");
        return produce(state, draftState => {
          draftState.authToken = action.payload.authToken;
          draftState.impersonateExpertAuthToken = null;
          draftState.impersonatingExpert = false;
          draftState.impersonateClientAuthToken = null;
          draftState.impersonatingClient = false;
          draftState.me = action.payload.user;
          draftState.otpVerified = action.payload.otpVerified;
        });
      }
      return state;
    case SessionAction.SET_IMPERSONATING_CLIENT:
      if (!state.impersonatingClient || state.impersonateClientAuthToken !== action.payload.authToken) {
        storage.set("IMPERSONATE_CLIENT_AUTH_TOKEN", action.payload.authToken);
        storage.remove("IMPERSONATE_EXPERT_AUTH_TOKEN");
        return produce(state, draftState => {
          draftState.impersonateExpertAuthToken = null;
          draftState.impersonatingExpert = false;
          draftState.impersonateClientAuthToken = action.payload.authToken;
          draftState.impersonatingClient = true;
          draftState.me = action.payload.user;
          draftState.otpVerified = true;
        });
      }
      return state;
    case SessionAction.SET_IMPERSONATING_EXPERT:
      if (!state.impersonatingExpert || state.impersonateExpertAuthToken !== action.payload.authToken) {
        storage.set("IMPERSONATE_EXPERT_AUTH_TOKEN", action.payload.authToken);
        storage.remove("IMPERSONATE_CLIENT_AUTH_TOKEN");
        return produce(state, draftState => {
          draftState.impersonateClientAuthToken = null;
          draftState.impersonatingClient = false;
          draftState.impersonateExpertAuthToken = action.payload.authToken;
          draftState.impersonatingExpert = true;
          draftState.me = action.payload.user;
          draftState.otpVerified = true;
        });
      }
      return state;
    default:
      return state;
  }
};

export default sessionReducer;
