import { useCallback, useContext, useMemo } from "react";

import { openerContext } from "@context/Contexts";
import { OpenerTypeEnum } from "@typing/Enums";

const useGlobalOpener = (openerType?: OpenerTypeEnum) => {
  const { openerState, toggleOpener } = useContext(openerContext);

  const isOpen = !!openerState.find(opener => opener.type === openerType)?.isOpen;

  const close = useCallback(() => {
    if (openerType) {
      toggleOpener(openerType, false);
    }
  }, [openerType, toggleOpener]);

  const open = useCallback(() => {
    if (openerType) {
      toggleOpener(openerType, true);
    }
  }, [openerType, toggleOpener]);

  const toggle = useCallback(() => {
    if (openerType) {
      toggleOpener(openerType, !isOpen);
    }
  }, [isOpen, openerType, toggleOpener]);

  const opener = useMemo(
    () => ({
      close,
      isOpen,
      open,
      toggle
    }),
    [close, isOpen, open, toggle]
  );

  return opener;
};

export default useGlobalOpener;
