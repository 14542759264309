import { KeysResult, Preferences } from "@capacitor/preferences";

async function set(key: string, value: string): Promise<void> {
  await Preferences.set({ key, value });
}

async function get(key: string): Promise<string | null> {
  return (await Preferences.get({ key })).value;
}

async function remove(key: string): Promise<void> {
  await Preferences.remove({ key });
}

async function keys(): Promise<KeysResult> {
  return await Preferences.keys();
}

const exports = { get, keys, remove, set };

export default exports;
