import { useContext } from "react";

import { sessionContext } from "@context/Contexts";

const useMe = () => {
  const { sessionState } = useContext(sessionContext);
  return sessionState.me;
};

export default useMe;
