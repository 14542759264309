import { Keyboard } from "@capacitor/keyboard";
import { createGesture, Gesture, GestureDetail } from "@ionic/react";
import { PropsWithChildren, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { isMobile, isNativeApp } from "@utils/platformUtils";

const GestureHandler = ({ children }: PropsWithChildren): ReactElement => {
  const history = useHistory();
  const target = useRef<HTMLDivElement | null>(null);
  const [keyboardGesture, setKeyboardGesture] = useState<Gesture>();

  const isSwipeableElement = (element: HTMLElement): boolean => element.closest(".swipeable") !== null;

  const onSwipeEnd = useCallback(
    (detail: GestureDetail) => {
      if (!detail?.velocityX || !detail.velocityY || isSwipeableElement(detail.event.target as HTMLElement)) {
        return;
      }

      if (Math.abs(detail.velocityX) > Math.abs(detail.velocityY) * 5.0) {
        if (detail.deltaX > 50) {
          history.goBack();
        } else if (detail.deltaX < -50) {
          history.goForward();
        }
      }
    },
    [history]
  );

  const onKeyboardSwipeMove = useCallback((detail: GestureDetail) => {
    if (detail.deltaY >= 150) {
      Keyboard.hide();
    }
  }, []);

  useEffect(() => {
    if (target.current && isMobile()) {
      const gesture = createGesture({
        direction: "x",
        el: target.current,
        gestureName: "historySwipes",
        onEnd: onSwipeEnd,
        priority: 1
      });

      gesture.enable();

      return () => {
        gesture.destroy();
      };
    }
  }, [onSwipeEnd]);

  useEffect(() => {
    if (isNativeApp()) {
      Keyboard.addListener("keyboardWillShow", () => {
        if (keyboardGesture) {
          keyboardGesture.enable(true);
        }
      });

      Keyboard.addListener("keyboardDidHide", () => {
        if (keyboardGesture) {
          keyboardGesture.enable(false);
        }
      });

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, [keyboardGesture]);

  useEffect(() => {
    if (target.current && isNativeApp()) {
      const gesture = createGesture({
        direction: "y",
        el: target.current,
        gestureName: "keyboardSwipe",
        onMove: onKeyboardSwipeMove,
        priority: 1
      });

      setKeyboardGesture(gesture);

      return () => {
        gesture.destroy();
      };
    }
  }, [onKeyboardSwipeMove]);

  return <div ref={target}>{children}</div>;
};

export default GestureHandler;
