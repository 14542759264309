import { ReactElement, ReactNode, useState } from "react";

import { fullscreenModalContext } from "@context/Contexts";
import styles from "@context/FullscreenModalStore.module.css";
import { FullscreenModalState } from "@typing/State";

const initialState: FullscreenModalState = {
  modals: []
};

const FullscreenModalStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [fullscreenModalState, setFullscreenModalState] = useState(initialState);

  return (
    <fullscreenModalContext.Provider
      value={{
        fullscreenModalState,
        setFullscreenModalState
      }}
    >
      <>
        {fullscreenModalState.modals.map(modal => {
          if (modal.isOpen) {
            return (
              <div className={styles.modalWrapper} key={modal.id}>
                {modal.content}
              </div>
            );
          }
          return null;
        })}
        {children}
      </>
    </fullscreenModalContext.Provider>
  );
};

export default FullscreenModalStore;
