import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useHistory } from "react-router";

import { getPathFromDeepLinkOrStandardUrl } from "@utils/urlUtils";

const AppUrlListener = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const path = getPathFromDeepLinkOrStandardUrl(event.url);

      if (path) {
        history.push(path);
      }
      // If we gat a nonstandard link, do nothing - let regular routing logic take over
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
