import * as LiveUpdates from "@capacitor/live-updates";
import { SplashScreen } from "@capacitor/splash-screen";
import { setUser } from "@sentry/react";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";

import { setAuthToken, setImpersonatingClient, setImpersonatingExpert } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import useInterval from "@hooks/useInterval";
import useLazyQueryWithPromise from "@hooks/useLazyQueryWithPromise";
import { liveUpdateAvailableStorageKey, storageTrueValue } from "@typing/Constants";
import { GetMeDocument, GetMeQuery, GetMeQueryVariables } from "@typing/Generated";
import { isNativeApp } from "@utils/platformUtils";
import Storage from "@utils/storageUtils";

type Props = { children: ReactNode };

const BootupProvider = ({ children }: Props) => {
  const { addInterval, clearIntervals } = useInterval();
  const { sessionState } = useContext(sessionContext);
  const { dispatch } = useContext(sessionContext);
  const initializingAuth = useRef<boolean>(true);
  const booting = useRef<boolean>(true);
  const checkingLiveUpdates = useRef<boolean>(true);
  const [, setForceRender] = useState<number>(0);
  const [forceInitializeAuth, setForceInitializeAuth] = useState<number>(0);

  useEffect(() => {
    if (sessionState.me) {
      const user = sessionState.me;
      if (user) {
        if (user.__typename === "Client") {
          setUser({
            email: user.clientEmail?.email,
            id: user.id,
            name: user.fullName,
            type: user.__typename
          });
        } else {
          setUser({
            email: user.email.email,
            id: user.id,
            name: user.fullName,
            type: user.__typename
          });
        }
      }
    }
  }, [sessionState.me]);

  const getMe = useLazyQueryWithPromise<GetMeQuery, GetMeQueryVariables>(GetMeDocument);

  useEffect(() => {
    addInterval(() => {
      if (initializingAuth.current === false && checkingLiveUpdates.current === false) {
        SplashScreen.hide();
        booting.current = false;
        setForceRender(Math.random());
        clearIntervals();
      } else if (initializingAuth.current === true) {
        setForceInitializeAuth(Math.random());
        setForceRender(Math.random());
      }
    }, 1000);
    // If I make this depend on addInterval and crateInterval then it will loop endlessly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If we're doing live updates
    //   See if we already know there is an update available
    //     If so, apply it
    //     If not, check to see if there is an update and apply it if so
    // If we're not doing live updates, we're all set
    if (isNativeApp()) {
      if (import.meta.env.VITE_PERFORM_LIVE_UPDATES === "yes") {
        Storage.get(liveUpdateAvailableStorageKey).then(storageCache => {
          if (storageCache === storageTrueValue) {
            Storage.remove(liveUpdateAvailableStorageKey).then(() => {
              LiveUpdates.reload();
            });
          } else {
            // otherwise we need to see if there is an update
            LiveUpdates.sync().then(result => {
              if (result.activeApplicationPathChanged) {
                LiveUpdates.reload();
              } else {
                checkingLiveUpdates.current = false;
              }
            });
          }
        });
      } else {
        checkingLiveUpdates.current = false;
      }
    } else {
      checkingLiveUpdates.current = false;
    }
  }, []);

  useEffect(() => {
    const bootFromStorage = async () => {
      const token = await Storage.get("AUTH_TOKEN");
      const clientToken = await Storage.get("IMPERSONATE_CLIENT_AUTH_TOKEN");
      const expertToken = await Storage.get("IMPERSONATE_EXPERT_AUTH_TOKEN");

      if (token) {
        getMe({ context: { authToken: token }, fetchPolicy: "network-only" })
          .then(response => {
            if (response.data.me) {
              dispatch(setAuthToken(token, response.data.me));
            }

            if (clientToken) {
              getMe({ context: { authToken: clientToken }, fetchPolicy: "network-only" }).then(response => {
                if (response.data.me) {
                  dispatch(setImpersonatingClient(clientToken, response.data.me));
                }
                initializingAuth.current = false;
              });
            }

            if (expertToken) {
              getMe({ context: { authToken: expertToken }, fetchPolicy: "network-only" }).then(response => {
                if (response.data.me) {
                  dispatch(setImpersonatingExpert(expertToken, response.data.me));
                }
                initializingAuth.current = false;
              });
            }

            if (!clientToken && !expertToken) {
              initializingAuth.current = false;
            }
          })
          .catch(() => {
            initializingAuth.current = false;
          });
      } else {
        initializingAuth.current = false;
      }
    };
    bootFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceInitializeAuth]);

  if (booting.current) {
    return null;
  }

  return <>{children}</>;
};

export default BootupProvider;
