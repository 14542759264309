import { AppState, App as CapacitorApp } from "@capacitor/app";
import { LocalNotifications } from "@capacitor/local-notifications";
import { ActionPerformed, PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { datadogLogs } from "@datadog/browser-logs";
import { useIonToast } from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router";

import useMe from "@hooks/useMe";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import {
  PushNotificationPlatformEnum,
  PushNotificationTokenCreateDocument,
  PushNotificationTokenCreateMutation,
  PushNotificationTokenCreateMutationVariables
} from "@typing/Generated";
import { IconList } from "@utils/iconUtils";
import { isAndroidNativeApp, isIosNativeApp, isNativeApp } from "@utils/platformUtils";
import { getPathFromDeepLinkOrStandardUrl } from "@utils/urlUtils";

const PushNotificationSetup = () => {
  const me = useMe();
  const registered = useRef<boolean>(false);
  const appActive = useRef<boolean>(true);
  const history = useHistory();

  const [present, dismiss] = useIonToast();

  const [createPushNotificationToken] = useMutationWithValidation<
    PushNotificationTokenCreateMutation,
    PushNotificationTokenCreateMutationVariables
  >(PushNotificationTokenCreateDocument, "pushNotificationToken");

  const platform = useMemo(() => {
    if (isIosNativeApp()) {
      return PushNotificationPlatformEnum.IOS;
    }
    return PushNotificationPlatformEnum.ANDROID;
  }, []);

  CapacitorApp.getState().then(appState => {
    appActive.current = appState.isActive;
  });

  CapacitorApp.addListener("appStateChange", (appState: AppState) => {
    appActive.current = appState.isActive;
  });

  CapacitorApp.addListener("pause", () => {
    appActive.current = false;
  });

  CapacitorApp.addListener("resume", () => {
    appActive.current = true;
  });

  const openNotificationUrl = useCallback(
    (url: string) => {
      const path = getPathFromDeepLinkOrStandardUrl(url);
      if (path) {
        datadogLogs.logger.info(`+++++++ Notification pushing to ${path}`);
        history.push(path);
      }
    },
    [history]
  );

  useEffect(() => {
    LocalNotifications.addListener("localNotificationActionPerformed", notification => {
      datadogLogs.logger.info(`+++++++ Notification action performed ${JSON.stringify(notification)}`);
      const urlString = notification.notification.extra;
      if (urlString) {
        openNotificationUrl(urlString);
      }
    });

    LocalNotifications.addListener("localNotificationReceived", notification => {
      datadogLogs.logger.info(`+++++++ Notification action received ${JSON.stringify(notification)}`);
    });

    return () => {
      LocalNotifications.removeAllListeners();
    };
  }, [openNotificationUrl]);

  useEffect(() => {
    if (isNativeApp() && me) {
      if (!registered.current) {
        registered.current = true;

        PushNotifications.addListener("registration", registrationData => {
          datadogLogs.logger.info(
            `++++++ Received a push notification registration ${JSON.stringify(registrationData)}`
          );
          createPushNotificationToken({
            platform,
            token: registrationData.value
          });
        });

        const registerForNotifications = async () => {
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive === "granted") {
            await PushNotifications.register();
          }
        };

        registerForNotifications();

        PushNotifications.addListener("registrationError", error => {
          datadogLogs.logger.info(`+++++ Error on push notification registration: ${JSON.stringify(error)}`);
        });

        PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
          // This means that either:
          //   * The app is open and we got a notification. The system one will not be displayed, so we need to make our own.
          //     Show a toast!
          //   * We're on android and the app is backgrounded and gets a notification. In this case, we need to create a
          //     local notification in order to tell Android to display it.
          datadogLogs.logger.info(
            `++++++ Received a push notification received, scheduling things ${JSON.stringify(notification)}`
          );

          const notificationBody = notification.body || notification.data.body;
          const notificationTitle = notification.title || notification.data.title;
          const notificationUrl = notification.data.url || notification.data.ab_uri;

          datadogLogs.logger.info(
            `++++++ Derived push notification data ${notificationBody} ${notificationTitle} ${notificationUrl}`
          );

          if (appActive.current) {
            present({
              buttons: [
                {
                  handler: () => {
                    openNotificationUrl(notificationUrl);
                  },
                  icon: IconList.arrowRightFromBracket
                },
                { handler: () => dismiss(), icon: IconList.xmark }
              ],
              cssClass: "pushNotificationToast",
              duration: 30_000,
              message: notificationBody,
              position: "top"
            });
          } else if (isAndroidNativeApp()) {
            LocalNotifications.schedule({
              notifications: [
                {
                  body: notificationBody,
                  extra: notificationUrl,
                  id: Math.floor(Math.random() * 2147483647),
                  title: notificationTitle
                }
              ]
            });
          }
        });

        PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
          datadogLogs.logger.info(
            `++++++ Received a push notification action performed ${JSON.stringify(notification)}`
          );

          const notificationUrl = notification.notification.data.url ?? notification.notification.data.ab_uri;

          datadogLogs.logger.info(`++++++ Derived push notification URL from data ${notificationUrl}`);

          // Method called when tapping on a notification when the app is not open. Push notifications can contain
          // structured data about actions that you want them to take, and ideally we would implement that, but for
          // now we're just opening up the URL that we assume the notification to contain.
          openNotificationUrl(notificationUrl);
        });
      }

      return () => {
        PushNotifications.removeAllListeners();
      };
    }
  }, [createPushNotificationToken, dismiss, me, openNotificationUrl, platform, present]);

  return null;
};

export default PushNotificationSetup;
