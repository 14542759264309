import storage from "@utils/storageUtils";

export const retrieveUtmParamsFromStorage = async () => {
  const utm = await Promise.all([
    storage.get("utmCampaign"),
    storage.get("utmContent"),
    storage.get("utmMedium"),
    storage.get("utmSource"),
    storage.get("utmTerm")
  ]);
  return {
    campaign: utm[0],
    content: utm[1],
    medium: utm[2],
    source: utm[3],
    term: utm[4]
  };
};
