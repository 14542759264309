import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import * as CookieConsent from "vanilla-cookieconsent";

import { cookieConsentContext } from "@context/Contexts";
import { CookieConsentContextType } from "@typing/State";
import { createCookieConsentConfig } from "@utils/cookieUtils";
import Storage from "@utils/storageUtils";

import "vanilla-cookieconsent/dist/cookieconsent.css";

type Props = React.PropsWithChildren;

const CookieConsentProvider = ({ children }: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUri = searchParams.get("redirectUri");
  const skipCookieBanner = searchParams.get("skipCookieBanner") === "true";
  const cookieBannerBlockedStorageKey = "COOKIE_BANNER_BLOCKED";

  const defaultStatus: CookieConsentContextType = {
    acceptsAnalyticsCookies: import.meta.env.VITE_ACCEPT_ALL_COOKIES ? true : null,
    acceptsFunctionalCookies: import.meta.env.VITE_ACCEPT_ALL_COOKIES ? true : null,
    acceptsNecessaryCookies: import.meta.env.VITE_ACCEPT_ALL_COOKIES ? true : null,
    acceptsPaymentCookies: import.meta.env.VITE_ACCEPT_ALL_COOKIES ? true : null
  };

  const [status, setStatus] = useState<CookieConsentContextType>(defaultStatus);

  // This is annoying AF. Apple keeps rejecting the app for not using their privacy tracking
  // thing simply because we are showing the cookie banner when trying to SSO with Gather. It seems
  // clear that they will not stop complaining about this even though it is not actually failing
  // to comply with their guidelines, so this makes it so we just don't display the cookie banner
  // when being used for SSO and accept all of the cookies automatically. This is technically less
  // compliant with Apple's BS, but I'm 100% sure it will make them stop complaining about it.
  const [initializeCookieBanner, setInitializeCookieBanner] = useState(false);

  useEffect(() => {
    if (redirectUri || skipCookieBanner) {
      Storage.set(cookieBannerBlockedStorageKey, "yes");
    }
  }, [redirectUri, skipCookieBanner]);

  useEffect(() => {
    if (initializeCookieBanner) {
      CookieConsent.run(createCookieConsentConfig(setStatus, intl));
    }
  }, [initializeCookieBanner, intl]);

  useEffect(() => {
    Storage.get(cookieBannerBlockedStorageKey).then((value: string | null) => {
      if (value === "yes" || !!redirectUri) {
        setStatus({
          acceptsAnalyticsCookies: true,
          acceptsFunctionalCookies: true,
          acceptsNecessaryCookies: true,
          acceptsPaymentCookies: true
        });
      } else {
        setInitializeCookieBanner(true);
      }
    });
  }, [redirectUri]);

  return <cookieConsentContext.Provider value={status}>{children}</cookieConsentContext.Provider>;
};

export default CookieConsentProvider;
