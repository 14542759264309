import { useMutation } from "@apollo/client";
import { getPlatforms } from "@ionic/react";
import { useEffect } from "react";
import { useLocation } from "react-router";

import useMe from "@hooks/useMe";
import {
  AnalyticsEventCreateDocument,
  AnalyticsEventCreateMutation,
  AnalyticsEventCreateMutationVariables
} from "@typing/Generated";
import { retrieveUtmParamsFromStorage } from "@utils/analyticsUtils";
import { extractResourcesFromPath } from "@utils/linkUtils";

const TrackPageview = () => {
  const me = useMe();
  const location = useLocation();

  const [createAnalyticsEvent] = useMutation<AnalyticsEventCreateMutation, AnalyticsEventCreateMutationVariables>(
    AnalyticsEventCreateDocument
  );

  getPlatforms();

  useEffect(() => {
    if (me?.id) {
      const path = location.pathname;
      const resources = extractResourcesFromPath(path);
      const utm = retrieveUtmParamsFromStorage();
      utm.then(utm => {
        createAnalyticsEvent({
          variables: {
            eventData: {
              page: location.pathname,
              platforms: getPlatforms(),
              resources,
              type: "pageview",
              utm
            }
          }
        });
      });
    }
  }, [createAnalyticsEvent, location.pathname, me?.id]);

  return null;
};

export default TrackPageview;
