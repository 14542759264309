import { ReactElement, ReactNode, RefObject, useCallback, useState } from "react";

import { scrollPositionContext } from "@context/Contexts";
import { ScrollPositionPageTypeEnum } from "@typing/Enums";
import { ScrollPositionState } from "@typing/State";

const initialState: ScrollPositionState = {
  currentRef: null,
  currentType: null,
  pages: Object.values(ScrollPositionPageTypeEnum).map(type => ({
    loading: false,
    topPosition: 0,
    type
  }))
};

const ScrollPositionStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [scrollPositionState, setScrollPositionState] = useState(initialState);

  const setContentRef = useCallback(
    (ref: RefObject<HTMLIonContentElement>) => {
      setScrollPositionState(prevState => ({
        ...prevState,
        currentRef: ref
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollPositionState]
  );

  const setCurrentType = useCallback(
    (type: ScrollPositionPageTypeEnum | null) => {
      setScrollPositionState(prevState => ({
        ...prevState,
        currentType: type
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollPositionState]
  );

  const scrollToSavedPosition = useCallback(() => {
    const currentPage = scrollPositionState.pages.find(page => page.type === scrollPositionState.currentType);

    if (scrollPositionState.currentRef && currentPage) {
      scrollPositionState.currentRef.current?.scrollToPoint(0, currentPage.topPosition, 0);
    }
  }, [scrollPositionState]);

  const setTopPosition = useCallback(
    (topPosition: number) => {
      setScrollPositionState(prevState => ({
        ...prevState,
        pages: prevState.pages.map(scrollPosition =>
          scrollPosition.type === scrollPositionState.currentType
            ? {
                ...scrollPosition,
                topPosition
              }
            : scrollPosition
        )
      }));
    },
    [scrollPositionState]
  );

  return (
    <scrollPositionContext.Provider
      value={{
        scrollPositionState,
        scrollToSavedPosition,
        setContentRef,
        setCurrentType,
        setTopPosition
      }}
    >
      {children}
    </scrollPositionContext.Provider>
  );
};

export default ScrollPositionStore;
