import { useLazyQuery } from "@apollo/client";
import { InAppReview } from "@capacitor-community/in-app-review";
import { useEffect } from "react";

import useMyClient from "@hooks/useMyClient";
import useTimeout from "@hooks/useTimeout";
import {
  GetClientWithReadyForReviewDocument,
  GetClientWithReadyForReviewQuery,
  GetClientWithReadyForReviewQueryVariables
} from "@typing/Generated";
import { isNativeApp } from "@utils/platformUtils";

const ReviewRequest = () => {
  const { addTimeout } = useTimeout();
  const myClient = useMyClient();

  const [, getClientWithReadyForReviewQuery] = useLazyQuery<
    GetClientWithReadyForReviewQuery,
    GetClientWithReadyForReviewQueryVariables
  >(GetClientWithReadyForReviewDocument, { fetchPolicy: "no-cache", variables: { id: myClient?.id ?? "" } });

  useEffect(() => {
    if (myClient && isNativeApp()) {
      addTimeout(() => {
        getClientWithReadyForReviewQuery.startPolling(300_000);
      }, 120_000);
    }
  }, [addTimeout, getClientWithReadyForReviewQuery, myClient]);

  useEffect(() => {
    if (getClientWithReadyForReviewQuery.data?.client.readyForReview) {
      InAppReview.requestReview();
    }
  }, [getClientWithReadyForReviewQuery.data?.client.readyForReview]);

  return null;
};

export default ReviewRequest;
