import { useContext } from "react";

import { sessionContext } from "@context/Contexts";
import { GetMeQuery } from "@typing/Generated";

type MyClient = Extract<GetMeQuery["me"], { __typename: "Client" }>;

const useMyClient: () => MyClient | undefined = () => {
  const { sessionState } = useContext(sessionContext);

  const me = sessionState.me;
  return me?.__typename === "Client" ? me : undefined;
};

export default useMyClient;
